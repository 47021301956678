import React, { useState, useRef, useEffect, useContext } from 'react'
import { createPortal } from 'react-dom'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { useZone } from 'use-zone-breakpoints'

import { IconButton } from '../../icon'
import { Modal } from '../../modal'
import { ModeToggle } from '../mode-toggle'
import { FilterForm } from './form'
import { useFilterPanel } from './filter-panel-manager'
import { mq } from '../../zoner'

import { ReactComponent as IconSearch } from '../../../images/svg/ic-search-24.svg'
import { ReactComponent as IconClose } from '../../../images/svg/ic-close-24.svg'

/**
 * consumer
 */

export const FilterMenu = () => {
  const { toggle } = useFilterPanel()
  return (
    <IconButton
      onClick={() => void toggle()}
      size={1.25}
      icon={<IconSearch />}
    />
  )
}

const StyledFilterPanel = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 25vw;
  height: 100vh;
  background-color: #14141a;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0;
  z-index: 1010;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '100%')});
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;

  ${mq[0]} {
    width: 100vw;
  }

  ${mq[1]} {
    width: 100vw;
  }
`

const styleHeader = css`
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  ${mq[0]} {
    height: 5rem;
    justify-content: flex-end;
  }

  ${mq[1]} {
    height: 5rem;
    justify-content: flex-end;
  }
`

const FilterPanelContent = () => {
  const { isOpen, toggle, searchVideo, currentMode, setMode } = useFilterPanel()
  const zone = useZone()

  return (
    <StyledFilterPanel isOpen={isOpen}>
      <div css={styleHeader}>
        {zone > 1 ? (
          <ModeToggle currentMode={currentMode} setMode={setMode} />
        ) : null}
        <IconButton
          onClick={() => void toggle()}
          size={1.25}
          icon={<IconClose />}
        >
          {zone < 2 ? 'Done' : null}
        </IconButton>
      </div>
      <FilterForm searchVideo={searchVideo} />
    </StyledFilterPanel>
  )
}

export const FilterPanel = () => {
  const $rootRef = useRef(null)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const $root = document.querySelector('#filter-panel')
    $rootRef.current = document.createElement('div')
    $root.appendChild($rootRef.current)
    setReady(true)

    return () => {
      $root.removeChild($rootRef.current)
    }
  }, [])

  if (!ready) return null

  return createPortal(<FilterPanelContent />, $rootRef.current)
}
