import React, { useEffect, useRef, useCallback } from 'react'
import { Global, css } from '@emotion/core'
import { graphql } from 'gatsby'
import throttle from 'lodash/throttle'
import { useZone } from 'use-zone-breakpoints'

import { Nav } from '../components/nav'
import { useClassicState } from '../hooks/classic-state'

import SEO from '../components/seo'
import Player from '../components/work/player'
import { ListContainer } from '../components/work/list'
import { BrowseContainer } from '../components/work/browse'
import {
  FilterPanelManager,
  useFilterPanel,
} from '../components/work/filter/filter-panel-manager'
import { FilterMenu, FilterPanel } from '../components/work/filter'

import URLSearchParams from '@ungap/url-search-params'
import { getPageName } from '../components/utils'

const infiniteConfig = {
  width: 0.25,
  ratio: 0.5625,
  cols: 4,
  gap: 15,
  snap: true,
}

const Message = ({ message }) => (
  <div
    css={css`
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  >
    <h1>{message}</h1>
  </div>
)

const ResultList = ({ videos }) => {
  const { resultVideos, currentMode, isOpen } = useFilterPanel()
  const zone = useZone()
  const isMobile = zone === 0
  // if (isSearching) return <Message message={'Searching...'} />
  if (resultVideos.length === 0) return <Message message={'No results found'} />

  const renderList = ({ play }) => (
    <ListContainer
      resultVideos={resultVideos}
      play={play}
      itemPerPage={isMobile ? 10 : 20}
      isOpen={isOpen}
    />
  )

  const renderBrowse = ({ play }) => (
    <BrowseContainer videos={videos} play={play} zone={zone} />
  )

  return currentMode === 'browse' && !isMobile ? (
    <Player render={renderBrowse} />
  ) : (
    <Player render={renderList} />
  )
}

const WorkPage = ({ data, location }) => {
  const videos = data.allVimeoVideo.nodes

  // const setUrl = query => {
  //   const { pathname } = location
  //   const queryString = new URLSearchParams()
  //   Object.keys(query).map(key => {
  //     queryString.set(key, query[key])
  //   })
  //   navigate(`${pathname}?${queryString}`, {
  //     replace: false,
  //   })
  // }

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search)
  //   const currentMode = params.get('mode')
  //   if (currentMode) setState({ currentMode })

  //   const clients = params.get('clients')
  //   const categories = params.get('categories')
  //   const year = params.get('year')
  //   if (clients || categories || year) {
  //     requestSearch({
  //       clients: {
  //         value: clients,
  //       },
  //       categories: {
  //         value: categories,
  //       },
  //       year: {
  //         value: year,
  //       },
  //     })
  //   }
  // }, [location])

  return (
    <FilterPanelManager videos={videos}>
      <SEO name="Work" />
      <Global
        styles={css`
          html,
          body {
            overscroll-behavior: none;
          }
        `}
      />
      <Nav right={<FilterMenu />} />
      <FilterPanel />
      <ResultList videos={videos} />
    </FilterPanelManager>
  )
}

export default WorkPage

export const query = graphql`
  query {
    allVimeoVideo(
      filter: { display: { eq: true } }
      sort: { fields: [year], order: DESC }
    ) {
      nodes {
        id
        duration
        categories
        _categories
        file {
          hq
          lq
        }
        preview {
          hq
          lq
          mq
        }
        client
        clientId
        year
        title
      }
    }
  }
`
