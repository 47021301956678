import { useState, useEffect } from 'react'

export const usePagination = ({ data, itemPerPage, firstPage = 0 }) => {
  const totalSize = data.length
  const [currentPage, setCurrentPage] = useState(firstPage)
  const totalPage = Math.ceil(totalSize / itemPerPage)

  const getItems = () => {
    const start = itemPerPage * currentPage
    const end = start + itemPerPage
    return data.slice(start, end)
  }

  const goToPage = i => {
    let v = +i
    if (isNaN(v) || v < 0 || v >= totalPage) return

    setCurrentPage(v)
  }

  useEffect(() => {
    setCurrentPage(0)
  }, [data])

  return {
    currentPage,
    totalPage,
    totalSize,
    items: getItems(),
    goToPage,
  }
}
