import React, { useRef, useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { useClassicState } from '../../../hooks/classic-state'
import { PageTitle, Label } from '../../typography'
import { mq } from '../../zoner'

import { Scrollable } from './scrollable'
import { Cell } from './cell'
import { SearchStringForm } from './search-string'

const styleFieldset = css`
  border: 0;
`

const StyledLegend = styled(Label)`
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
`.withComponent('legend')

const Form = ({ name, label, values, reset, selected, setSelected }) => {
  return (
    <form css={styleFieldset}>
      <StyledLegend>{label}</StyledLegend>
      <Scrollable>
        <ul>
          {values.map(({ label, value, count }) => (
            <li key={value}>
              <Cell
                value={value ? value : ''}
                label={label}
                count={count}
                name={name}
                selected={selected}
                setSelected={setSelected}
              />
            </li>
          ))}
        </ul>
      </Scrollable>
    </form>
  )
}

const FormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
`

const FormContainer = styled.div`
  padding: 0.5rem;
  padding-right: 1rem;

  ${mq[0]} {
    padding: 0.5rem 2rem;
  }
`

const ButtonReset = styled.button`
  border: 1px solid transparent;
  border-radius: 4px;
  background: transparent;
  padding: 0.25rem 0.5rem;
  color: white;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;

  &:hover,
  &:focus {
    border: 1px solid rgba(255, 255, 255, 0.4);
  }

  &:active {
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
`
const filterName = ['year', 'categories', 'clients']

const filterLabels = {
  clients: 'Clients',
  categories: 'Categories',
  year: 'Year',
}

const initInput = {
  year: '',
  categories: '',
  clients: '',
  string: '',
}

const RawFilterForm = ({ searchVideo }) => {
  const { searchies } = useStaticQuery(graphql`
    query {
      searchies {
        year {
          value
          label
          count
        }
        categories {
          value
          label
          count
        }
        clients {
          value
          label
          count
        }
      }
    }
  `)
  // only one string at a time.
  const [currentInput, setCurrentInput] = useState(initInput)

  // string-specific.
  // Raw value is string's input before clicking search button
  // searched marks whether to display search icon or close icon
  const [rawValue, setRawValue] = useState('')
  const [searched, setSearched] = useState(false)

  const updateCurrentInput = name => value => {
    setCurrentInput({
      ...initInput,
      [name]: value,
    })
    searchVideo({
      type: name,
      value: value,
    })
  }

  const reset = () => {
    setRawValue('')
    setSearched(false)
    setCurrentInput(initInput)
    searchVideo({
      type: 'reset',
    })
  }

  const handleReset = e => {
    e.preventDefault()
    reset()
  }

  return (
    <FormContainer>
      <FormHeader>
        <PageTitle align="left">Filter</PageTitle>
        <ButtonReset onClick={handleReset}>Reset</ButtonReset>
      </FormHeader>
      <SearchStringForm
        value={currentInput.string}
        setValue={updateCurrentInput('string')}
        reset={reset}
        rawValue={rawValue}
        setRawValue={setRawValue}
        searched={searched}
        setSearched={setSearched}
      />
      {filterName.map(name => (
        <Form
          key={name}
          label={filterLabels[name]}
          name={name}
          values={searchies[name]}
          selected={currentInput[name]}
          setSelected={updateCurrentInput(name)}
        />
      ))}
    </FormContainer>
  )
}

export const FilterForm = React.memo(RawFilterForm)
