import React, { useState } from 'react'
import styled from '@emotion/styled'
import { IconButton } from '../../icon'
import { ReactComponent as IconSearch } from '../../../images/svg/ic-search-24.svg'
import { ReactComponent as IconClose } from '../../../images/svg/ic-close-24.svg'
import { mq } from '../../zoner'

const StyledForm = styled.form`
  padding: 0.5rem;
`

const InputGroup = styled.div`
  display: flex;
  background-color: ${({ isFocused }) =>
    isFocused ? 'rgba(255,255,255,0.04)' : 'transparent'};
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: background-color 0.2s ease;
`

const StyledInput = styled.input`
  border: 1px transparent;
  background-color: transparent;
  color: #fff;
  padding: 0.5em 0.5em;
  flex: 0 1 auto;
  width: calc(100% - 3rem);
`

const StyledButton = styled.button`
  flex: 0 1 3rem;
  width: 3rem;
`

export const SearchStringForm = ({
  value,
  setValue,
  reset,
  searched,
  setSearched,
  rawValue,
  setRawValue,
}) => {
  const [isFocused, setFocused] = useState(false)

  const handleInput = e => {
    setRawValue(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setValue(rawValue)
    setSearched(true)
  }

  const handleReset = e => {
    e.preventDefault()
    reset()
  }

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    setFocused(false)
  }

  const isDisabled = !rawValue.trim()

  return (
    <StyledForm autoComplete="off">
      <InputGroup isFocused={isFocused}>
        <StyledInput
          type="text"
          name="search"
          placeholder="Search..."
          value={rawValue}
          onChange={handleInput}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
        {searched ? (
          <IconButton icon={<IconClose />} onClick={handleReset} />
        ) : (
          <IconButton
            icon={<IconSearch />}
            onClick={handleSubmit}
            disabled={isDisabled}
          />
        )}
      </InputGroup>
    </StyledForm>
  )
}
