import React, { useEffect, useRef } from 'react'
import { css } from '@emotion/core'
import { mq } from '../../zoner'

const styleInputGroup = css`
  position: relative;
  display: flex;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  transition: transform 0.3s ease;
  font-size: 0.9rem;

  ${mq[0]} {
    font-size: 1.25rem;
  }
  ${mq[1]} {
    font-size: 1rem;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }

  &:after {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: white;
    border-radius: 0.75rem;
    top: 0.5rem;
    right: 0.5rem;
    transform: translateX(20%);
    opacity: 0;
    transition: all 0.2s ease;
  }

  input:checked + & {
    color: #62627a;
    background-color: rgba(255, 255, 255, 0.04);
    transform: scale(0.96);

    &:hover {
      background-color: rgba(255, 255, 255, 0.08);
    }

    &:after {
      transform: translateX(0%);
      opacity: 1;
    }
  }
`

const styleCount = css`
  flex: 0 1 20%;
  text-align: right;
  font-size: 0.8em;
  opacity: 0.4;
`

const styleInput = css`
  appearance: none;
  position: absolute;
  opacity: 0;
`

const styleLabel = css`
  flex: 0 1 80%;
  line-height: 1.2;
`
// If count === -1, it's the 'All' node, which
// needs to be selected by default.
export const Cell = ({ name, label, count, value, selected, setSelected }) => {
  const id = `input-${name}-${value || 'all'}`
  const displayCount = count === -1 ? ' ' : count

  const handleChange = e => {
    setSelected(e.target.value)
  }

  return (
    <div>
      <input
        type="radio"
        onChange={handleChange}
        css={styleInput}
        id={id}
        value={value}
        name={name}
        data-name={label}
        checked={selected == value}
      />
      <label css={styleInputGroup} htmlFor={id}>
        <div css={styleLabel}>{label}</div>
        <div css={styleCount}>{displayCount}</div>
      </label>
    </div>
  )
}
