import React, { useState, useEffect } from 'react'
import throttle from 'lodash/throttle'

import Infinite from './infinite'
import Cell from './cell'

const zoneToWidthMap = [1, 0.5, 0.33, 0.25]

const RawBrowseContainer = ({ play, videos, zone }) => {
  const infiniteWidth = zoneToWidthMap[zone] || 0.25

  return (
    <Infinite
      key={infiniteWidth}
      config={{
        contentSize: videos.length,
        width: infiniteWidth,
      }}
      render={({ contentId, slotId }) => (
        <Cell
          videos={videos}
          play={play}
          contentId={contentId}
          slotId={slotId}
        />
      )}
    />
  )
}

export const BrowseContainer = React.memo(RawBrowseContainer)
