import React, { useEffect, useCallback } from 'react'
import { css } from '@emotion/core'
import { mq } from '../utils'
import { Icon } from '../icon'
import { ReactComponent as IconClose } from '../../images/svg/ic-close-24.svg'
import Modal from '../modal'

import { useClassicState } from '../../hooks/classic-state'

const Player = ({ close, content }) => {
  const { clients, title, year, source, poster } = content
  return (
    <>
      <button
        css={css`
          display: inline-block;
          background: none;
          border: none;
          width: 2.5rem;
          height: 2.5rem;
          position: absolute;
          z-index: 1105;
          top: 2rem;
          right: 2rem;

          ${mq[0]} {
            top: auto;
            right: auto;
            left: 50%;
            bottom: 3rem;
            transform: translateX(-50%);
          }
        `}
        onClick={close}
      >
        <Icon icon={<IconClose />} />
      </button>
      <div
        css={css`
          position: absolute;
          top: 2rem;
          left: 1.5rem;
          z-index: 1105;
          margin-right: 2rem;

          ${mq[0]} {
            left: 1rem;
          }

          & > h1,
          h2,
          h3 {
            font-size: 1.25rem;
            font-weight: normal;
            display: inline-block;
            margin-right: 1rem;
            opacity: 1;
          }

          h1 {
            opacity: 0.8;
          }

          h3 {
            opacity: 0.6;
          }
        `}
      >
        <h2>{clients}</h2>
        <h1>{title}</h1>
        <h3>{year}</h3>
      </div>
      <video
        data-object-fit="contain"
        css={css`
            position: absolute;
            top: 0
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;

            ${mq[0]} {
              top: 45%;
              transform: translateY(-50%);
            }
          `}
        src={source}
        poster={poster}
        controls
        autoPlay
      />
    </>
  )
}

const PlayerContainer = ({ render }) => {
  const [state, setState] = useClassicState({
    isOpen: false,
    content: {},
  })

  const play = useCallback(
    content => {
      setState({
        isOpen: true,
        content,
      })
    },
    [setState]
  )

  const open = useCallback(() => {
    setState({
      isOpen: true,
    })
  }, [setState])

  const close = useCallback(() => {
    setState({
      isOpen: false,
    })
  }, [setState])

  useEffect(() => {
    import('objectFitPolyfill')
  }, [])

  const { isOpen, content } = state

  return (
    <>
      <Modal isOpen={isOpen} background="#000">
        <Player close={close} content={content} />
      </Modal>
      {render({ open, close, play })}
    </>
  )
}

export default PlayerContainer
