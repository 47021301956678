import React from 'react'
import { css } from '@emotion/core'

import { Icon } from '../icon'
import { ReactComponent as IconBrowse } from '../../images/svg/ic-mode-browse-24.svg'
import { ReactComponent as IconList } from '../../images/svg/ic-mode-list-24.svg'

const ModeButton = ({ mode, currentMode, setMode }) => (
  <button
    css={css`
      border: none;
      background: transparent;
      color: #fff;
      opacity: 0.4;

      &:disabled {
        opacity: 1;
      }
    `}
    onClick={() => setMode(mode)}
    disabled={mode === currentMode}
  >
    <Icon
      size={1.25}
      icon={mode === 'browse' ? <IconBrowse /> : <IconList />}
    />
  </button>
)

export const ModeToggle = ({ currentMode, setMode }) => (
  <div
    css={css`
      text-align: right;
      margin-right: 1.5rem;
    `}
  >
    <span
      css={css`
        margin-right: 0.5rem;
      `}
    >
      Display
    </span>
    <ModeButton mode="list" {...{ currentMode, setMode }} />
    <ModeButton mode="browse" {...{ currentMode, setMode }} />
  </div>
)
