import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import lunr from '../../../../custom-lunr'
import { useZone } from 'use-zone-breakpoints'

// don't include 'string' because we
// treat it specially
const queryTypePrefixMap = {
  year: 'year',
  clients: 'clientId',
  categories: '_categories',
}

/**
 * Set up context for filter panel
 */
const FilterPanelContext = React.createContext({
  isOpen: true,
  toggle: () => {},
})
export const useFilterPanel = () => useContext(FilterPanelContext)

export const FilterPanelManager = ({ children, videos }) => {
  // FILTER PANEL TOGGLE
  const zone = useZone()
  const [isOpen, setOpen] = useState(true)
  // toggle unless a boolean is passed in
  const toggle = toggleValue => {
    const shouldOpen = typeof toggleValue === 'boolean' ? toggleValue : !isOpen
    setOpen(shouldOpen)
  }
  useEffect(() => {
    if (zone < 2 && isOpen) {
      setOpen(false)
    } else if (zone >= 2 && !isOpen) {
      setOpen(true)
    }
  }, [zone])

  // MODE
  // collapse filter panel when switching to browse
  const [currentMode, setCurrentMode] = useState('list')
  const setMode = mode => {
    if (mode === 'browse') setOpen(false)
    setCurrentMode(mode)
  }

  // SEARCH
  // lunr search feature
  const serializedIdx = useStaticQuery(graphql`
    query {
      lunrIdx {
        fileName
      }
    }
  `)

  const searchIdx = useRef(null)
  const [resultVideos, setResultVideos] = useState(videos)

  const indexVideo = async () => {
    const seriallized = await fetch(`/${serializedIdx.lunrIdx.fileName}`).then(
      res => res.json()
    )
    const idx = lunr.Index.load(seriallized)
    searchIdx.current = idx
  }

  const searchVideo = useCallback(
    query => {
      // no query or query type is 'reset'
      if (
        typeof query === 'undefined' ||
        query.type === 'reset' ||
        query.value === ''
      ) {
        setResultVideos(videos)
        return
      }
      const idx = searchIdx.current
      const { type, value } = query

      const queryString =
        type === 'string'
          ? `${value} ${value}*`
          : queryTypePrefixMap[type] + ':' + value

      const results = idx.search(queryString)
      // filter videos instead of map results, so
      // the order of video is preserved.
      const resultVideos = videos.filter(({ id }) =>
        results.find(({ ref }) => id === ref)
      )

      setResultVideos(resultVideos)

      // display search results in list mode only
      setCurrentMode('list')
    },
    [videos]
  )

  useEffect(() => {
    indexVideo()
  }, [])

  return (
    <FilterPanelContext.Provider
      value={{
        isOpen,
        toggle,

        currentMode,
        setMode,

        searchVideo,
        resultVideos,
      }}
    >
      {children}
    </FilterPanelContext.Provider>
  )
}
