import React, { useState, useCallback } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { mq } from '../../zoner'
import { Icon } from '../../icon'
import { ReactComponent as Arrow } from '../../../images/svg/arrow.svg'
import { usePagination } from '../../../hooks/pagination'
import { useFilterPanel } from '../filter/filter-panel-manager'
import { PageTitle } from '../../typography'
import { Cell } from './cell'

const NavigationButton = ({ goToPage, totalPage, currentPage, direction }) => {
  const isNext = direction === 'next'
  const destination = isNext ? currentPage + 1 : currentPage - 1
  return (
    <button
      css={css`
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        border: none;
        background: transparent;
        transform: translateX(${isNext ? '-10%' : '10%'});
        transition: 0.3s ease;
        padding: 0;

        &:disabled {
          opacity: 0.2;
        }

        ${mq[0]} {
          margin: 0.75rem;
        }
      `}
      disabled={destination < 0 || destination > totalPage}
      onClick={() => {
        window.scrollTo(0, 0)
        goToPage(destination)
      }}
    >
      <Icon icon={<Arrow />} rotation={isNext ? 0 : 180} />
    </button>
  )
}

const Menu = ({ currentPage, totalPage, goToPage }) => (
  <div
    css={css`
      width: 100%;
      height: 3rem;
      padding: 0.5rem 0;
      position: relative;
      z-index: 100;
      text-align: center;
      opacity: 0.7;
      transition: 0.3s ease;

      &:hover {
        opacity: 1;
        & > button {
          transform: translateX(0);
        }
      }

      & > h4 {
        font-size: 0.8rem;
        display: inline-block;
        width: 5rem;
        margin-bottom: 0;
      }

      ${mq[0]} {
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > button {
          transform: translateX(0);
        }

        & > h4 {
          font-size: 1rem;
        }
      }
    `}
  >
    <NavigationButton
      direction="previous"
      totalPage={totalPage}
      currentPage={currentPage}
      goToPage={goToPage}
    />
    <h4>{`${currentPage + 1} / ${totalPage}`}</h4>
    <NavigationButton
      direction="next"
      totalPage={totalPage}
      currentPage={currentPage}
      goToPage={goToPage}
    />
  </div>
)

const Container = styled.div`
  position: relative;
  width: 75vw;
  max-width: 60rem;
  margin: 7rem 0;
  padding: 0 2rem;
  transform: translateX(${({ isOpen }) => (isOpen ? 0 : '12.5vw')});

  ${mq[0]} {
    width: 100%;
    margin: 5rem 0;
    padding: 0;
    transform: translateX(0);
  }

  transition: transform 0.5s ease;
  transition-delay: 0.1s;
`

const styleUl = css`
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
`

const styleLi = css`
  flex: 0 1 auto;
  width: 33%;
  position: relative;
  padding: 0.5rem 0.25rem;
  box-sizing: border-box;

  ${mq[0]} {
    width: 100%;
    padding: 0.5rem 0;
  }

  ${mq[1]} {
    width: 50%;
  }

  ${mq[2]} {
    width: 50%;
  }

  ${mq[3]} {
    width: 50%;
  }

  ${mq[4]} {
    width: 33%;
  }
`

const YearHeader = styled(PageTitle)`
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;

  ${mq[0]} {
    margin-left: 1rem;
  }

  ${mq[1]} {
    margin-left: 1rem;
  }
`

const RawList = ({ items, play }) => {
  // group items by year so we can divide the list into year subgroup
  const groupedByYear = items.reduce((acc, video) => {
    const { year } = video
    if (acc[year]) acc[year].push(video)
    else acc[year] = [video]
    return acc
  }, {})

  return (
    <>
      {Object.entries(groupedByYear)
        // recent year first
        .sort((a, b) => b[0] - a[0])
        .map(([year, groupedItems]) => {
          return (
            <div key={year}>
              <YearHeader align="left">{year == 0 ? 'TBA' : year}</YearHeader>
              <ul css={styleUl}>
                {groupedItems.map((video, i) => (
                  <li css={styleLi} key={video.preview.mq}>
                    <Cell play={play} video={video} id={i} />
                  </li>
                ))}
              </ul>
            </div>
          )
        })}
    </>
  )
}

const itemCheck = (prev, next) => {
  return (
    prev.play === next.play &&
    prev.items.length === next.items.length &&
    prev.items[0].id === next.items[0].id
  )
}
const List = React.memo(RawList, itemCheck)

export const ListContainer = ({ resultVideos, play, itemPerPage, isOpen }) => {
  const { items, goToPage, currentPage, totalPage, totalSize } = usePagination({
    data: resultVideos,
    itemPerPage,
    firstPage: 0,
  })

  return (
    <Container key={totalSize} isOpen={isOpen}>
      <List items={items} play={play} />
      <Menu {...{ currentPage, totalPage, totalSize, goToPage }} />
    </Container>
  )
}
