import { useState, useCallback } from 'react'

export const useClassicState = initState => {
  const [state, setState] = useState(initState)
  const setStateMerge = useCallback(
    updater => {
      setState(state => {
        const nextState =
          typeof updater === 'function' ? updater(state) : updater
        return {
          ...state,
          ...nextState,
        }
      })
    },
    [setState]
  )
  return [state, setStateMerge]
}
