import React, { useRef, useEffect, useState } from 'react'
import throtte from 'lodash/throttle'
import { css } from '@emotion/core'

const styleScrollContainer = css`
  position: relative;
  height: 30vh;
  overflow: hidden;
`

const styleScrollable = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
`

const styleShadow = css`
  position: absolute;
  left: 0;
  width: 100%;
  height: 3rem;
  pointer-events: none;
  background: linear-gradient(rgba(20, 20, 26, 1), rgba(20, 20, 26, 0));
  z-index: 100;
  transition: opacity 0.3s ease;
`
/**
 * A scroll list with shadow to indicate scroll direction.
 * Height measurement is applied to the first child DOM element.
 */
export const Scrollable = ({ height, children }) => {
  // top | middle | bottom
  const [position, setPosition] = useState('top')
  const scrollRef = useRef(null)

  useEffect(() => {
    const $scrollable = scrollRef.current
    const $content = $scrollable.firstChild
    if (!$content) return

    const scrollableHeight = $scrollable.getBoundingClientRect().height
    const contentHeight = $content.getBoundingClientRect().height

    const scrollHandler = e => {
      const scrollPos = e.target.scrollTop
      if (scrollPos < 20) setPosition('top')
      else if (scrollPos > contentHeight - scrollableHeight - 20)
        setPosition('bottom')
      else setPosition('middle')
    }
    const throttled = throtte(scrollHandler, 100, { leading: true })
    $scrollable.addEventListener('scroll', throttled)
    return () => {
      $scrollable.removeEventListener('scroll', throttled)
    }
  }, [])

  return (
    <div css={styleScrollContainer} style={{ height: `${height}rem` }}>
      <div
        css={styleShadow}
        style={{ top: 0, opacity: position === 'top' ? 0 : 1 }}
      />
      <div
        css={styleShadow}
        style={{
          bottom: 0,
          transform: 'rotate(180deg)',
          opacity: position === 'bottom' ? 0 : 1,
        }}
      />
      <div ref={scrollRef} css={styleScrollable}>
        {children}
      </div>
    </div>
  )
}
